<script setup lang="ts">
import { computed } from 'vue'
import { useCart } from '/~/composables/cart'
import { useCheckoutReactive } from '/~/composables/checkout'
import SummaryAddressesItem from './summary-addresses-item.vue'

const { payment } = useCheckoutReactive()
const { cartOrderAddresses } = useCart()

const billingAddress = computed(() => {
  return cartOrderAddresses.billing
})
const shippingAddress = computed(() => {
  return cartOrderAddresses.shipping
})
</script>

<template>
  <div v-if="payment.isAddressSelectionAllowed" class="mb-5 border-b pb-5">
    <summary-addresses-item
      v-if="billingAddress"
      :address="billingAddress"
      title="Billing"
    />
    <summary-addresses-item
      v-if="shippingAddress"
      :address="shippingAddress"
      title="Shipping"
      class="mt-4"
    />
  </div>
</template>
